@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alegreya:wght@400;700&family=Bagel+Fat+One&family=Cantarell&family=Cherry+Swash&family=Fjalla+One&family=Flamenco&family=Indie+Flower&family=Josefin+Sans:wght@300&family=Nabla&family=Oswald&family=Ribeye+Marrow&family=Sancreek&family=Special+Elite&family=Tilt+Prism&family=Ubuntu&family=Yeseva+One&family=Zilla+Slab+Highlight:wght@700&display=swap');

/* Default */

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  font-family: 'Josefin Sans', 'Cherry Swash', sans-serif;
  color: white;
  background-image: url('../src/img/bg-clark-board.png');
  object-fit: cover;
} 

a {
  color: inherit;
  text-decoration: none;
}

/* Layout */

header {
  position: relative;
}

main {
  color: white;
}

.main-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem auto 0;
}

.current-guess {
  font-size: 6rem;
  background: white;
  color: black;
  text-align: center;
  width: 14.5rem;
  padding: 3rem 0rem;
  margin: 2rem;
}

.guess-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  margin: 3rem;
  width: 395px;
  height: 280px;
}

.message-box {
  display: flex;
  justify-content: center;
}

.message-board {
  width: 390px;
  padding: 2rem;
}

/* Elements Style*/

#title {
  text-align: center;
  margin: 3rem auto;
}

h1 {
  font-size: 5rem;
  font-family: 'Abril Fatface', 'Tilt Prism', 'Nabla', 'Ribeye Marrow', monospace;
  text-shadow: 0.3rem 0.3rem 0.1rem rgba(255, 255, 255, 0.4);
}

.between {
  font-size: 2rem;
  margin: 0.3rem auto 0;
}

.new-game {
  margin: 1.5rem;
}

.btn {
  border: none;
  background-color: white;
  color: black;
  font-size: 2rem;
  font-family:  'Josefin Sans', 'Arial Narrow', Arial, sans-serif;
  font-weight: bold;
  border-radius: 30px;
  padding: 2rem;
  cursor: pointer;
  transition: transform 0.1s;
}

.btn:hover {
  color: white;
  background-color: #faca2d;
  font-weight: bold;
  transform: scale(1.05);
}

.btn:active {
  transform: scale(0.95);
}

.guess {
  background: none;
  border: 4px solid white;
  font-family: inherit;
  color: inherit;
  font-size: 5rem;
  padding: 2.5rem;
  width: 18rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.correct-guess {
  font-weight: bolder;
  color: #faca2d;
  font-size: 8rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.guess-options {
  display: flex;
  justify-content: center;
}

.decrease, .increase {
  width: 45px;
  margin: auto 12px;
  transition: transform 0.1s;
}

.decrease:hover, .increase:hover {
  transform: scale(1.1);
}

.decrease:active, .increase:active {
  transform: scale(0.9);
}

.input-err-message {
  font-family: inherit;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem;
}

.submit-guess {
  padding: 2rem;
}

.score-board {
  font-size: 24px;
  font-weight: bold;
}

.current-score-label, .highest-score-label {
  margin-bottom: 2rem;
}

.message {
  margin: 0.5rem 0 5rem;
  font-size: 32px;
}

.won, .lost {
  font-weight: 700;
  font-family: 'Zilla Slab Highlight', 'Nabla', 'Arial Narrow', Arial, sans-serif;
  font-size: 45px;
  animation: float 0.77s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.main-won {
  background-image: url('./img/win1.png');
  object-fit: contain;
}

.main-lost {
  background-image: url('./img/lost.png');
  object-fit: contain;
}

.icon-score-board {
  width: 50px;
}

/* Footer */

.footer {
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.social-icon {
  display: inline-block;
  margin: 2rem;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin: 0 1rem;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

@media (max-width: 1024px) {
  .main-box-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  } 
}

@media (max-width: 900px) {
  #title {
    text-align: center;
    margin: 1rem auto;
  }
  
  h1 {
    font-size: 30px;
    text-shadow: 0.2rem 0.2rem 0.07rem rgba(255, 255, 255, 0.4);
  }

  .between {
    font-size: 18px;
    padding: 1rem 0;
  }

  .main-box {
    margin: 10px 0 0;
  }

  .btn {
    padding: 1.5rem;
    margin: 1rem;
    font-size: 1.8rem;
  }

  .current-guess {
    font-size: 42px; 
    background: white;
    color: black;
    text-align: center;
    width: 106px;
    padding: 2rem auto;
    margin: 0;
  }

  .input-err-message {
    font-size: 16px;
    font-weight: bold;
    margin: 0.3rem;
  }

  .guess {
    font-size: 38px;
    padding: 1.8rem;
    width: 15rem;
    margin: 0;
  }

  .message-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } 

  .score-board {
    font-size: 20px;
  }

  .icon-score-board {
    width: 40px;
  }

  .current-score-label, .highest-score-label {
    margin-bottom: 1rem;
  }
  
  .message {
    font-size: 26px;
    margin: 0.5rem 0 2rem;
  }
  
  .won, .lost {
    font-size: 35px;
  }

  .correct-guess {
    color: #faca2d;
  }

  .message-board {
    padding: 8px 20px;
    margin-bottom: 15px;
  }

  .guess-box {
    margin: 0;
    padding: 15px 3px;
    height: 200px;
  }

  .footer {
    margin: 0.5rem 0;
  }

  .social-icon {
    margin: 2rem auto 1rem;
  }
}